<header>
    <div class="container-1280 header-container">
        <div>
            <a href="/"><img src="assets/images/logos/bitex-blanco.svg" alt="Bitex" class="logo-header-desktop"></a>
        </div>
        <div class="header-links-container {{cssLinks}}">
            <ul>
                <li><a [ngClass]=" { 'active': active == 'individuals' } " href="/home/individuals">INDIVIDUALS</a></li>
                <li><a [ngClass]=" { 'active': active == 'advance' } " href="/home/advance">ADVANCE</a></li>
                <li><a [ngClass]=" { 'active': active == 'corporate' } " href="/home/corporate">CORPORATE</a></li>
                <li><a [ngClass]=" { 'active': active == 'partners' } " href="/home/partners">PARTNERS</a></li>
                <li><a href="" class="go-to-contact">CONTACTO</a></li>
            </ul>
        </div>
        <div class="btn-group" role="group">
            <a href="https://exchange.bitex.la/login" target="_blank" type="button" class="btn btn-login">LOGIN</a>
            <a href="https://exchange.bitex.la/signup" target="_blank" type="button" class="btn btn-signup">SIGN UP</a>
        </div>
    </div>
</header>