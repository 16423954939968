import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvanceComponent } from './pages/advance/advance.component';
import { CorporateComponent } from './pages/corporate/corporate.component';
import { HomeComponent } from './pages/home/home.component';
import { IndividualsComponent } from './pages/individuals/individuals.component';
import { PartnersComponent } from './pages/partners/partners.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		runGuardsAndResolvers: "always"
	},
	{
		path: 'home/individuals',
		component: IndividualsComponent,
		runGuardsAndResolvers: "always"
	},
	{
		path: 'home/advance',
		component: AdvanceComponent,
		runGuardsAndResolvers: "always"
	},
	{
		path: 'home/corporate',
		component: CorporateComponent,
		runGuardsAndResolvers: "always"
	},
	{
		path: 'home/partners',
		component: PartnersComponent,
		runGuardsAndResolvers: "always"
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
