import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { IndividualsComponent } from './pages/individuals/individuals.component';
import { AdvanceComponent } from './pages/advance/advance.component';
import { CorporateComponent } from './pages/corporate/corporate.component';
import { ContactComponent } from './components/contact/contact.component';
import { AvailableCryptosComponent } from './components/available-cryptos/available-cryptos.component';
import { LocalCurrencyComponent } from './components/local-currency/local-currency.component';
import { AssetSafeguardComponent } from './components/asset-safeguard/asset-safeguard.component';
import { DiversifyComponent } from './components/diversify/diversify.component';
import { PartnersComponent } from './pages/partners/partners.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    IndividualsComponent,
    AdvanceComponent,
    CorporateComponent,
    ContactComponent,
    AvailableCryptosComponent,
    LocalCurrencyComponent,
    AssetSafeguardComponent,
    DiversifyComponent,
    PartnersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
