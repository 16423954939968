<section class="home-s5 form-container">
	<div class="container-1280">
		<div class="form no-p-top">
			<ng-container *ngIf="showFireblocks">
				<div class="form-title-container">
					<p class="form-title">Resguardo de activos</p>
					<p class="form-text">Para ofrecerle a nuestros clientes un servicio de calidad, Bitex eligió como proveedor estratégico a uno de los custodios más serios del mundo, una empresa
						israelí regulada e inscripta como custodio crypto en Estados Unidos. Además cuenta
						con pólizas de seguros emitidas por Lloyd 's of London.</p>
					<div class="form-logo">
						<img src="assets/images/logos/fireblocks.png" alt="Fireblocks">
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="showContact">
				<app-contact></app-contact>
			</ng-container>
		</div>
	</div>
</section>
