<section class="corporate-s1">
    <div class="container-1280 corporates1-container">
        <div class="homes1-title-container">
            <p class="homes1-title individuals1-title">Corporate</p>
            <p class="homes1-subtitle advances1-subtitle">Lleve a su compañía al próximo nivel de evolución financiera</p>
            <p class="homes1-subtitle advances1-subtitle-text">para aquellos que quieran invertir, diversificar, resguardar y potenciar el valor de sus activos en criptoactivos</p>
        </div>
        <p class="homes1-text individuals1-text">Nuestros ejecutivos lo acompañarán en su proceso de evolución financiera</p>
        <div class="homes1-icon-container corporates1-icon-container">
            <div class="homes1-icon corporates1-icon">
                <img src="assets/images/iconos/corporate-01.svg" alt="Alta de su cuenta">
                <p>Alta de su cuenta</p>
            </div>
            <div class="homes1-icon corporates1-icon">
                <img src="assets/images/iconos/corporate-02.svg" alt="Transferencia / Acreditación">
                <p>Transferencia / Acreditación</p>
            </div>
            <div class="homes1-icon corporates1-icon">
                <img src="assets/images/iconos/corporate-03.svg" alt="Trading">
                <p>Trading</p>
            </div>
            <div class="homes1-icon corporates1-icon">
                <img src="assets/images/iconos/corporate-04.svg" alt="Seguimiento de sus inversiones">
                <p>Seguimiento de sus inversiones</p>
            </div>
            <div class="homes1-icon corporates1-icon">
                <img src="assets/images/iconos/corporate-05.svg" alt="Informes de sus operaciones">
                <p>Informes de sus operaciones</p>
            </div>
        </div>
        <div class="corporates1-btn-container">
            <a href="https://www.bitex.la/" target="_blank" class="btn-white-corporate">MÁS INFORMACIÓN</a>
        </div>
    </div>
</section>

<app-diversify></app-diversify>
<app-local-currency></app-local-currency>

<section class="corporate-s4">
    <div class="container-1280">
        <div>
            <p class="homes3-title corporates4-title">Pilares fundamentales para tomar las mejores decisiones</p>
            <div class="corporates4-card-container">
                <div class="homes2-item-container corporates4-item-container">
                    <div class="homes2-item-img corporates4-item-img">
                        <img src="assets/images/iconos/corporatePilares-01.svg" alt="Riesgos únicos para cada activo digital">
                    </div>
                    <p class="corporates4-item-title">Riesgos únicos para cada activo digital</p>
                    <div class="corporates4-item-text collapse" id="collapseExample">
                        <p>
                            Los riesgos subyacentes a los activos digitales varían considerablemente. En consecuencia, las empresas tienen que ser muy rigurosas en cuanto a cómo el activo o la moneda operan y las vulnerabilidades del mercado que estén relacionadas. Las compañías
                            tienen que entender el blockchain que respalda cada activo y como funciona la gobernanza de ese sistema.
                        </p>
                    </div>
                    <button class="btn btn-blue-corporate btn-mas" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
						VER MÁS
					</button>
                </div>
                <div class="homes2-item-container corporates4-item-container">
                    <div class="homes2-item-img corporates4-item-img">
                        <img src="assets/images/iconos/corporatePilares-02.svg" alt="Riesgos de autorización">
                    </div>
                    <p class="corporates4-item-title">Riesgos de autorización</p>
                    <div class="corporates4-item-text collapse" id="collapseExample2">
                        <p>
                            Las empresas deben distinguir las tareas de manera clara y con documentación en donde esté especificado quién tiene acceso a las claves de las cuentas y qué transacción cada persona puede o no puede emprender. Esto garantiza el seguimiento oportuno de
                            las transacciones que se lleven a cabo en blockchain y que esas operaciones fueran aprobadas o no.
                        </p>
                    </div>
                    <button class="btn btn-blue-corporate btn-mas" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2">
						VER MÁS
					</button>
                </div>
                <div class="homes2-item-container corporates4-item-container">
                    <div class="homes2-item-img corporates4-item-img">
                        <img src="assets/images/iconos/corporatePilares-03.svg" alt="Cumplimiento normativo">
                    </div>
                    <p class="corporates4-item-title">Cumplimiento normativo</p>
                    <div class="corporates4-item-text collapse" id="collapseExample3">
                        <p>
                            Entender si la compañía puede invertir en criptoactivos de acuerdo al marco regulatorio en el que opera y asesorarse del impacto impositivo y normativo que va a tener la toma de decisión en su compañia. Al igual que con la contabilidad y los impuestos,
                            las reglas y las regulaciones varían según la jurisdicción. Por lo tanto, para garantizar el cumplimiento de estas normas recomendamos la participación durante la operación de un asesor legal informado.
                        </p>
                    </div>
                    <button class="btn btn-blue-corporate btn-mas" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3">
						VER MÁS
					</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="corporate-s5">
    <div class="container-1280">
        <div class="corporates5-icon-container corporates5-icon-desktop">
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-01.svg">
                <p>Atención personalizada</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-02.svg">
                <p>Proceso riguroso de AML/KYC</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-03.svg">
                <p>Invierta y diversifique</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-04.svg">
                <p>Acceda en moneda local</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-05.svg">
                <p>Obtenga liquidez</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-06.svg">
                <p>Custodia</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-07.svg">
                <p>Trading online precios servicio 24x7</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-08.svg">
                <p>Precios internacionales</p>
            </div>
            <div class="corporates5-icon">
                <img src="assets/images/iconos/corporateBackground-09.svg">
                <p>Servicio a medida</p>
            </div>
        </div>
        <div class="owl-carousel owl-theme carousel-corporates5 corporates5-icon-mobile">
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-01.svg">
                    <p>Atención personalizada</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-02.svg">
                    <p>Proceso riguroso de AML/KYC</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-03.svg">
                    <p>Invierta y diversifique</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-04.svg">
                    <p>Acceda en moneda local</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-05.svg">
                    <p>Obtenga liquidez</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-06.svg">
                    <p>Custodia</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-07.svg">
                    <p>Trading online precios servicio 24x7</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-08.svg">
                    <p>Precios internacionales</p>
                </div>
            </div>
            <div class="item">
                <div class="corporates5-icon">
                    <img src="assets/images/iconos/corporateBackground-09.svg">
                    <p>Servicio a medida</p>
                </div>
            </div>
        </div>
        <div class="corporates5-card-container">
            <div class="homes4-card-container individuals5-card-container">
                <div class="homes4-card corporates5-card">
                    <img src="assets/images/iconos/corporateCard-01.svg">
                    <p class="homes4-card-text corporates5-text">
                        Encuentre asesoramiento personalizado para la compra y venta de criptoactivos a través de nuestro equipo especializado.
                    </p>
                </div>
                <div class="homes4-card corporates5-card">
                    <img src="assets/images/iconos/corporateCard-02.svg">
                    <p class="homes4-card-text corporates5-text">
                        Creemos que las instituciones requieren servicios confiables y de alta disponibilidad para realizar operaciones y resguardar sus inversiones en activos digitales.
                    </p>
                </div>
                <div class="homes4-card corporates5-card">
                    <img src="assets/images/iconos/corporateCard-03.svg">
                    <p class="homes4-card-text corporates5-text">
                        Para orientarlo en su estrategia corporativa, le recomendaremos cumplir fases de planeamiento y ejecución durante el desarrollo de su evolución financiera.
                    </p>
                </div>
            </div>
            <div class="owl-carousel owl-theme carousel-homes4 corporates5-card-mobile">
                <div class="item">
                    <div class="homes4-card corporates5-card">
                        <img src="assets/images/iconos/corporateCard-01.svg">
                        <p class="homes4-card-text corporates5-text">
                            Encuentre asesoramiento personalizado para la compra y venta de criptoactivos a través de nuestro equipo especializado.
                        </p>
                    </div>
                </div>
                <div class="item">
                    <div class="homes4-card corporates5-card">
                        <img src="assets/images/iconos/corporateCard-02.svg">
                        <p class="homes4-card-text corporates5-text">
                            Creemos que las instituciones requieren servicios confiables y de alta disponibilidad para realizar operaciones y resguardar sus inversiones en activos digitales.
                        </p>
                    </div>
                </div>
                <div class="item">
                    <div class="homes4-card corporates5-card">
                        <img src="assets/images/iconos/corporateCard-03.svg">
                        <p class="homes4-card-text corporates5-text">
                            Para orientarlo en su estrategia corporativa, le recomendaremos cumplir fases de planeamiento y ejecución durante el desarrollo de su evolución financiera.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="corporate-s6">
    <div class="container 1280">
        <img src="assets/images/img/mariposa-mobile.svg" class="corporate-mariposa">
        <p class="homes3-title">Diferencias entre mercados</p>
        <div class="corporates6-card-container">
            <div class="corporates6-card">
                <p class="corporates6-card-title">Mercado tradicional</p>
                <img src="assets/images/img/corporateMercado-02.svg" class="corporates6-card-img">
                <p class="corporates6-card-text-blanco">Opera según calendario y horario de mercado</p>
                <p class="corporates6-card-text-violeta">Centralizado y regulado</p>
                <p class="corporates6-card-text-blanco">Intervienen varios actores</p>
                <p class="corporates6-card-text-violeta">Cada estado tiene su propia FIAT</p>
                <p class="corporates6-card-text-blanco">Mayor burocracia, menos ágil</p>
                <p class="corporates6-card-text-violeta">Mayores costos: fees & spreads, impuestos, etc</p>
                <p class="corporates6-card-text-blanco">Solo para inversión, intercambio o atesoramiento</p>
                <p class="corporates6-card-text-violeta">Valor influenciado por la inflación, contextos, balances, etc</p>
                <p class="corporates6-card-text-blanco">Emitidos por los gobiernos</p>
                <p class="corporates6-card-text-violeta">Manipulado por varios actores: reguladores</p>
                <p class="corporates6-card-text-blanco">Custodia a cargo de varios intermediarios</p>
            </div>
            <div class="corporates6-card">
                <p class="corporates6-card-title">Mercado de criptoactivos</p>
                <img src="assets/images/img/corporateMercado-01.svg" class="corporates6-card-img">
                <p class="corporates6-card-text-blanco">Opera 24/7 x 365 días al año</p>
                <p class="corporates6-card-text-violeta">Descentralizado (ej: no hay un Bco. Central que lo regule)</p>
                <p class="corporates6-card-text-blanco">Sin intermediarios</p>
                <p class="corporates6-card-text-violeta">No pertenecen a ningún estado o país</p>
                <p class="corporates6-card-text-blanco">Transferencias inmediatas</p>
                <p class="corporates6-card-text-violeta">Menos costos: fees & spreads</p>
                <p class="corporates6-card-text-blanco">Más versátil, aplica a varios usos: inversión, contratos, etc</p>
                <p class="corporates6-card-text-violeta">Valor basado por la oferta/demanda (cada vez con más fundamentos)</p>
                <p class="corporates6-card-text-blanco">Emitidas a través de la "minería"</p>
                <p class="corporates6-card-text-violeta">No es manipulable</p>
                <p class="corporates6-card-text-blanco">Los puede custodiar usted mismo</p>
            </div>
        </div>
    </div>
</section>

<app-asset-safeguard></app-asset-safeguard>