import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-asset-safeguard',
	templateUrl: './asset-safeguard.component.html',
	styleUrls: ['./asset-safeguard.component.css']
})
export class AssetSafeguardComponent implements OnInit {

	@Input()
	showContact: boolean = true;

	@Input()
	showFireblocks: boolean = true;

	constructor() { }

	ngOnInit(): void {
	}

}
