import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

	constructor() { }
	captchaImg = 'https://crm.zoho.com/crm/CaptchaServlet?formId=f526c21da78de3e04a5386b47f25bdd59d637dca8e2fca45ea80ff57384002f9&grpid=2bb2492eb4517fc737858a2d5e4ad1e1621d5f9283b7deda1321c00640874b01&d' + new Date().getTime();

	ngOnInit(): void {
	}

	/* Do not remove this code. */
	reloadImgCaptcha() {
		this.captchaImg = 'https://crm.zoho.com/crm/CaptchaServlet?formId=f526c21da78de3e04a5386b47f25bdd59d637dca8e2fca45ea80ff57384002f9&grpid=2bb2492eb4517fc737858a2d5e4ad1e1621d5f9283b7deda1321c00640874b01&d' + new Date().getTime();
	}

}
