<section class="home-s1 individuals-s1">
    <div class="container-1280 homes1-container individuals-s1-container">
        <div class="homes1-title-container">
            <p class="homes1-title individuals1-title">Individuals</p>
            <p class="homes1-subtitle individuals1-subtitle">Adquiera criptoactivos, de manera simple, ágil y segura</p>
        </div>
        <p class="homes1-text individuals1-text">Comprar y vender criptomonedas es muy fácil</p>
        <div class="homes1-icon-container">
            <div class="homes1-icon individuals1-icon">
                <div class="individuals-icon-num">1</div>
                <p>Regístrese gratis y valide su identidad</p>
            </div>
            <div class="homes1-icon individuals1-icon">
                <div class="individuals-icon-num">2</div>
                <p>Transfiera local o internacionalmente</p>
            </div>
            <div class="homes1-icon individuals1-icon">
                <div class="individuals-icon-num">3</div>
                <p>Compre y venda criptomonedas</p>
            </div>
        </div>
        <div class="individuals1-btn-container">
            <a href="https://exchange.bitex.la/signup" target="_blank" class="btn-verde-individuals">COMIENCE AHORA</a>
        </div>
    </div>
</section>

<app-diversify></app-diversify>

<app-local-currency></app-local-currency>

<app-available-cryptos></app-available-cryptos>

<section class="home-s1 individuals-s5">
    <div class="container-1280">
        <div class="individuals5-title-container">
            <p class="homes4-title individuals-s5-title">Disponga de las comisiones más <br>competitivas del mercado</p>
            <p class="individuals-s5-subtitle">(*1) Tarifas dinámicas sujetas al tráfico de blockchain correspondiente. (*2) Estos costos no los cobra Bitex International CV. Son cobrados por los partners de las diferentes jurisdicciones. Todos los costos adicionales (impuestos, comisiones,
                gastos, etc) que puedan aplicar las entidades bancarias por las que se cursen las operaciones corren a su cargo.</p>
        </div>
        <div class="homes4-card-container individuals5-card-container">
            <div class="homes4-card homes4-card3 individuals5-card">
                <p class="homes4-card-title individuals5-title">Fases y comisiones</p>
                <p class="homes4-card-text individuals5-text">
                    <strong>Trading</strong> <br> 0.25% (por operación/ trade)
                </p>
                <p class="homes4-card-text individuals5-text">
                    <strong>Custodia</strong> <br> 0.5% (anual)
                </p>
                <p class="homes4-card-text individuals5-text">
                    <strong>Withdraw Crypto</strong> <br> Fee de la red (*1)
                </p>
            </div>
            <div class="homes4-card homes4-card3 individuals5-card">
                <p class="homes4-card-title individuals5-title">Costos de carga y descarga de saldo</p>
                <p class="homes4-card-text individuals5-text">
                    <strong>Transferencias locales (ARS, CLP, UYU, PYG)</strong> <br> 0.5% (*2)
                </p>
                <p class="homes4-card-text individuals5-text">
                    <strong>Transferencias internacionales en USD</strong> <br> 75 USD (*2)
                </p>
            </div>
        </div>
        <div class="owl-carousel owl-theme carousel-homes4">
            <div class="item">
                <div class="homes4-card homes4-card3 individuals5-card">
                    <p class="homes4-card-title individuals5-title">Fases y comisiones</p>
                    <p class="homes4-card-text individuals5-text">
                        <strong>Trading</strong> <br> 0.25% (por operación/ trade)
                    </p>
                    <p class="homes4-card-text individuals5-text">
                        <strong>Custodia</strong> <br> 0.5% (anual)
                    </p>
                    <p class="homes4-card-text individuals5-text">
                        <strong>Withdraw Crypto</strong> <br> Fee de la red (*1)
                    </p>
                </div>
            </div>
            <div class="item">
                <div class="homes4-card homes4-card3 individuals5-card">
                    <p class="homes4-card-title individuals5-title">Costos de carga y descarga de saldo</p>
                    <p class="homes4-card-text individuals5-text">
                        <strong>Transferencias locales (ARS, CLP, UYU, PYG)</strong> <br> 0.5% (*2)
                    </p>
                    <p class="homes4-card-text individuals5-text">
                        <strong>Transferencias internacionales en USD</strong> <br> 75 USD (*2)
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<div style="display: block; margin-top:-120px">
    <app-asset-safeguard></app-asset-safeguard>
</div>