<section class="partners-s1">
    <div class="container-1280 homes1-container">
        <div class="homes1-title-container">
            <p class="homes1-title">Partners</p>
        </div>
        <div class="homes1-icon-container">
            <div class="homes1-icon partners1-icon">
                <img src="assets/images/iconos/partners-01.svg">
                <p>Segmento dirigido exclusivamente para asesores financieros</p>
            </div>
            <div class="homes1-icon partners1-icon">
                <img src="assets/images/iconos/partners-02.svg">
                <p>Seguridad operativa y legal para banca privada como banca coorporativa</p>
            </div>
            <div class="homes1-icon partners1-icon">
                <img src="assets/images/iconos/partners-03.svg">
                <p>Asesoramiento personalizado para brindar distintas alternativas de inversión</p>
            </div>
        </div>
    </div>
    <div class="container-1280">
        <p class="partners2-title">Encuentre en <br>Bitex Partners</p>
    </div>
    <div class="container-1280 partners1-icon-container-mobile">
        <div class="owl-carousel owl-theme carousel-individuals2">
            <div class="item">
                <div class="individuals2-item">
                    <img src="assets/images/iconos/partnersBitex-01.svg" alt="Atención personalizada">
                    <p>Atención personalizada</p>
                </div>
            </div>
            <div class="item">
                <div class="individuals2-item">
                    <img src="assets/images/iconos/partnersBitex-02.svg" alt="Obtenga liquidez en moneda local">
                    <p>Obtenga liquidez en moneda local</p>
                </div>
            </div>
            <div class="item">
                <div class="individuals2-item">
                    <img src="assets/images/iconos/partnersBitex-03.svg" alt="Custodia segura">
                    <p>Custodia segura</p>
                </div>
            </div>
            <div class="item">
                <div class="individuals2-item">
                    <img src="assets/images/iconos/partnersBitex-04.svg" alt="Opere online">
                    <p>Opere online</p>
                </div>
            </div>
            <div class="item">
                <div class="individuals2-item">
                    <img src="assets/images/iconos/partnersBitex-05.svg" alt="Precios internacionales">
                    <p>Precios internacionales</p>
                </div>
            </div>
            <div class="item">
                <div class="individuals2-item">
                    <img src="assets/images/iconos/partnersBitex-06.svg" alt="Servicio a medida">
                    <p>Servicio a medida</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-1280">
        <div class="partners1-item-container">
            <div class="partners1-item">
                <img src="assets/images/iconos/partnersBitex-01.svg" alt="Atención personalizada">
                <p>Atención personalizada</p>
            </div>
            <div class="partners1-item">
                <img src="assets/images/iconos/partnersBitex-02.svg" alt="Obtenga liquidez en moneda local">
                <p>Obtenga liquidez en moneda local</p>
            </div>
            <div class="partners1-item">
                <img src="assets/images/iconos/partnersBitex-03.svg" alt="Custodia segura">
                <p>Custodia segura</p>
            </div>
            <div class="partners1-item">
                <img src="assets/images/iconos/partnersBitex-04.svg" alt="Opere online">
                <p>Opere online</p>
            </div>
            <div class="partners1-item">
                <img src="assets/images/iconos/partnersBitex-05.svg" alt="Precios internacionales">
                <p>Precios internacionales</p>
            </div>
            <div class="partners1-item">
                <img src="assets/images/iconos/partnersBitex-06.svg" alt="Servicio a medida">
                <p>Servicio a medida</p>
            </div>
        </div>
    </div>
</section>

<section class="partners-s3">
    <div class="container-1280">
        <img src="assets/images/img/mariposa-mobile-rotada.svg" class="partners3-mariposa-mobile">
        <p class="partners3-title">Propuesta exclusiva</p>
        <div class="partners3-card-container">
            <div class="homes4-card homes4-card2">
                <p class="homes4-card-title">Partners</p>
                <p class="homes4-card-text">Encuentre en nuestros asesores toda la información y el acompañamiento que necesite para que sus clientes tengan herramientas alternativas al mercado tradicional. Obtenga liquidez internacional, diversifique su portfolio con el respaldo,
                    la seguridad operativa y legal que ofrece Bitex.</p>
                <a href="/home/partners" class="homes4-card-btn">VER MÁS</a>
            </div>
            <img src="assets/images/img/mariposa-rotada.svg" class="partners3-mariposa-desktop">
        </div>
    </div>
</section>

<app-available-cryptos></app-available-cryptos>

<section class="home-s5 form-container">
    <div class="container-1280">
        <div class="form no-p-top">
            <div class="form-title-container">
                <p class="form-title">Resguardo de activos</p>
                <p class="form-text">Para ofrecerle a nuestros clientes un servicio de calidad, Bitex eligió como proveedor estratégico a uno de los custodios más serios del mundo, una empresa israelí regulada e inscripta como custodio crypto en Estados Unidos. Además cuenta
                    con pólizas de seguros emitidas por Lloyd 's of London.</p>
                <div class="form-logo">
                    <img src="assets/images/logos/fireblocks.png" alt="Fireblocks">
                </div>
            </div>
            <app-contact></app-contact>
        </div>
    </div>
</section>