<section class="home-s3 individuals-s3">
    <div class="container-1280">
        <div class="homes3-container">
            <div class="homes3-img-container">
                <img src="assets/images/img/mariposa.svg" class="homes3-img desktop">
                <img src="assets/images/img/mariposa-mobile.svg" class="homes3-img mobile">
            </div>
            <div class="homes3-info-container individuals3-info-container">
                <p class="homes3-title">Acceda en moneda local</p>
                <div class="homes3-flag-container">
                    <div class="homes3-flag">
                        <img src="assets/images/flags/arg.svg" alt="Argentina">
                        <p>ARS</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/cl.svg" alt="Chile">
                        <p>CLP</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/peru.svg" alt="Perú">
                        <p>PEN</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/par.svg" alt="Paraguay">
                        <p>PYG</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/usa.svg" alt="EEUU">
                        <p>USD</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/uyu.svg" alt="Uruguay">
                        <p>UYU</p>
                    </div>
                </div>
            </div>
            <div class="individuals3-elipse-container">
                <img src="assets/images/bg/elipse.png" class="elipse-individuals3">
            </div>
        </div>
    </div>
</section>