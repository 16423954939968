import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


	cssLinks: string = "";
	active: string = "";
	constructor() {
		switch (location.pathname) {
			case "/home/individuals":
				this.cssLinks = "header-links-individuals";
				this.active = "individuals";
				break;
			case "/home/advance":
				this.cssLinks = "header-links-advance";
				this.active = "advance";
				break;
			case "/home/corporate":
				this.cssLinks = "header-links-corporate";
				this.active = "corporate";
				break;
			case "/home/partners":
				this.cssLinks = "header-links-partners";
				this.active = "partners";
				break;
			default:
				break;
		}

		$("body").addClass(this.active);
	}

	ngOnInit(): void {

	}

}
