<section class="individuals-s2 advance-s2">
    <div class="container-1280">
        <div class="individuals2-title-container">
            <p class="individuals2-title">Diversifique sus inversiones en más de 15 criptomonedas</p>
            <p class="individuals2-subtitle">Disponemos de una amplia variedad de criptoactivos para que pueda diversificar y componer un portafolio de inversión atractivo para sus objetivos.</p>
        </div>
        <div>
            <div class="owl-carousel owl-theme carousel-individuals2">
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-bitcoin.svg" alt="Bitcoin">
                        <p>BTC</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-usdt.svg" alt="USDT">
                        <p>USDT</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-dai.svg" alt="DAI">
                        <p>DAI</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-binance.svg" alt="Binance">
                        <p>BINANCE</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-bitcoincash.svg" alt="Bitcoin Cash">
                        <p>BCH</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-ethereum.svg" alt="Ethereum">
                        <p>ETH</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-polkadot.svg" alt="Polkadot">
                        <p>DOT</p>
                    </div>
                </div>
                <!--div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-ftt.svg" alt="FTT">
                        <p>FTT</p>
                    </div>
                </div-->
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-usdc.svg" alt="USDC">
                        <p>USDC</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-uniswap.svg" alt="Uniswap">
                        <p>UNI</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-doge.svg" alt="Dogecoin">
                        <p>DOGE</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-link.svg" alt="LINK">
                        <p>LINK</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-soya.svg" alt="SOYA">
                        <p>SOYA</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-yfi.svg" alt="YFI">
                        <p>YFI</p>
                    </div>
                </div>
                <div class="item">
                    <div class="individuals2-item">
                        <img src="assets/images/logos/logo-sushi.svg" alt="SUSHI">
                        <p>SUSHI</p>
                    </div>
                </div>
            </div>
            <div class="advances2-btn-container">
                <a href="https://exchange.bitex.la/signup" target="_blank" class="btn-blue-advance">ABRA SU CUENTA</a>
            </div>
        </div>
    </div>
</section>
