<section class="home-s1 advance-s1">
	<div class="container-1280 homes1-container individuals-s1-container">
		<div class="homes1-title-container">
			<p class="homes1-title individuals1-title">Advance</p>
			<p class="homes1-subtitle advances1-subtitle">Un servicio exclusivo, dedicado y a medida</p>
			<p class="homes1-subtitle advances1-subtitle-text">para individuos de alto patrimonio que quieran diversificar sus inversiones en criptoactivos.</p>
		</div>
		<p class="homes1-text individuals1-text">Encuentre en Bitex Advance</p>
		<div class="homes1-icon-container advances1-icon-container">
			<div class="homes1-icon advances1-icon">
				<img src="assets/images/iconos/advance-01.svg" alt="Atención personalizada">
				<p>Atención personalizada</p>
			</div>
			<div class="homes1-icon advances1-icon">
				<img src="assets/images/iconos/advance-02.svg" alt="Máxima discreción y excelencia">
				<p>Máxima discreción y excelencia</p>
			</div>
			<div class="homes1-icon advances1-icon">
				<img src="assets/images/iconos/advance-03.svg" alt="Accesibilidad 24x7">
				<p>Accesibilidad 24x7</p>
			</div>
			<div class="homes1-icon advances1-icon">
				<img src="assets/images/iconos/advance-04.svg" alt="Competitividad y liquidez">
				<p>Competitividad y liquidez</p>
			</div>
			<div class="homes1-icon advances1-icon">
				<img src="assets/images/iconos/advance-05.svg" alt="Seguimiento de sus inversiones">
				<p>Seguimiento de sus inversiones</p>
			</div>
		</div>
	</div>

</section>

<div class="advance-home-safe">
	<app-asset-safeguard [showContact]="false"></app-asset-safeguard>
</div>

<app-diversify></app-diversify>

<section class="home-s3 advance-s3">
	<div class="container-1280">
		<div class="homes3-container advances3-container">
			<div class="advance-s3-icon-container-mobile">
				<div class="advance-s3-icon-mobile-info">
					<p class="advances3-icon-title">¿Cómo ser un cliente Advance?</p>
					<p class="advances3-icon-text">Si quiere adquirir más de cincuenta mil dólares en cripto activos, hable con nosotros para obtener atención personalizada y máxima discreción.</p>
				</div>
			</div>
			<div class="advance-s3-icon-container">
				<img src="assets/images/img/advance-s3.png" class="fondo-advance">
				<div class="texto-advance">
					<p class="advances3-icon-title">¿Cómo ser un cliente Advance?</p>
					<p class="advances3-icon-text">Si quiere adquirir más de cincuenta mil dólares en cripto activos, hable con nosotros para obtener atención personalizada y máxima discreción.</p>
				</div>
			</div>
			<div class="homes3-info-container advances3-info-container">
				<p class="homes3-title">Acceda en moneda local</p>
				<div class="homes3-flag-container">
					<div class="homes3-flag">
						<img src="assets/images/flags/arg.svg" alt="Argentina">
						<p>ARS</p>
					</div>
					<div class="homes3-flag">
						<img src="assets/images/flags/cl.svg" alt="Chile">
						<p>CLP</p>
					</div>
					<div class="homes3-flag">
						<img src="assets/images/flags/peru.svg" alt="Perú">
						<p>PEN</p>
					</div>
					<div class="homes3-flag">
						<img src="assets/images/flags/par.svg" alt="Paraguay">
						<p>PYG</p>
					</div>
					<div class="homes3-flag">
						<img src="assets/images/flags/usa.svg" alt="EEUU">
						<p>USD</p>
					</div>
					<div class="homes3-flag">
						<img src="assets/images/flags/uyu.svg" alt="Uruguay">
						<p>UYU</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<aside class="p-relative trama-advance-container">
	<img src="assets/images/bg/trama.svg" class="trama-advance">
</aside>

<app-available-cryptos></app-available-cryptos>

<app-asset-safeguard [showFireblocks]="false"></app-asset-safeguard>
