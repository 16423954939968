<footer class="footer">
    <div class="container-1280">
        <div class="footer-container">
            <div class="footer-logo-container">
                <img src="assets/images/logos/bitex-azul.svg" alt="Bitex" class="logo-footer-bitex">
                <div class="footer-social-container">
                    <a href="https://www.twitter.com/bitexla" target="_blank"><img src="assets/images/iconos/tw.svg" alt="Twitter"></a>
                    <a href="https://www.facebook.com/bitex.la" target="_blank"><img src="assets/images/iconos/fb.svg" alt="Facebook"></a>
                    <a href="https://www.instagram.com/bitex.la" target="_blank"><img src="assets/images/iconos/ig.svg" alt="Instagram"></a>
                </div>
            </div>
            <div class="footer-link-container">
                <p class="footer-link-title">Compañía</p>
                <a target="_blank" href="/assets/Terminos-y-Condiciones.pdf" class="footer-link">T&C</a>
                <a target="_blank" href="/assets/Politica-de-Seguridad.pdf" class="footer-link">Seguridad</a>
                <a href="" class="footer-link go-to-contact">Soporte / Contact us</a>
            </div>
            <div class="footer-link-container">
                <p class="footer-link-title">Servicios</p>
                <a href="/home/individuals" class="footer-link">Individuals</a>
                <a href="/home/advance" class="footer-link">Advance</a>
                <a href="/home/corporate" class="footer-link">Corporate</a>
                <a href="/home/partners" class="footer-link">Partners</a>
            </div>
            <div class="footer-link-container">
                <p class="footer-link-title">Recursos</p>
                <a href="https://bitex.zendesk.com/hc/es" target="_blank" class="footer-link">Bitex FAQ</a>
                <a href="https://bitex.zendesk.com/hc/es/articles/4411328384786-4-2-Fees-y-Comisiones" target="_blank" class="footer-link">Comisiones</a>
            </div>
        </div>
        <div class="copy-container">
            <p>© 2022 Bitex. All rights reserved.</p>
            <div class="copy-logo-container">
                <p>Powered by</p>
                <a href="https://www.id4you.com" target="_blank">
                    <img src="assets/images/logos/id.svg" alt="">
                </a>
            </div>
        </div>
    </div>
</footer>