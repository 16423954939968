<div class="menu-mobile-container">
    <a href="/"><img src="assets/images/logos/bitex-blanco.svg" class="logo-menu-mobile"></a>
    <button class="btn menu-mobile-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
		<div class="menu-toggle">
			<span class="line1"></span>
			<span class="line2"></span>
			<span class="line3"></span>
		</div>
	</button>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <div class="menu-mobile">
            <a href="/"><img src="assets/images/logos/bitex-azul.svg" alt="Bitex" class="logo-header-mobile"></a>
            <ul>
                <li><a href="/home/individuals">INDIVIDUALS</a></li>
                <li><a href="/home/advance">ADVANCE</a></li>
                <li><a href="/home/corporate">CORPORATE</a></li>
                <li><a href="/home/partners">PARTNERS</a></li>
                <li><a href="" class="go-to-contact">CONTACTO</a></li>
            </ul>
            <div class="btn-group" role="group">
                <a href="https://exchange.bitex.la/login" target="_blank" type="button" class="btn btn-login">LOGIN</a>
                <a href="https://exchange.bitex.la/signup" target="_blank" type="button" class="btn btn-signup">SIGN UP</a>
            </div>
            <div class="rrss-header-mobile-container">
                <p>Seguinos</p>
                <div class="rrss-header-mobile">
                    <a href="https://www.twitter.com/bitexla" target="_blank">
                        <img src="assets/images/iconos/tw.svg" alt="Twitter">
                    </a>
                    <a href="https://www.facebook.com/bitex.la" target="_blank">
                        <img src="assets/images/iconos/fb.svg" alt="Facebook">
                    </a>
                    <a href="https://www.instagram.com/bitex.la" target="_blank">
                        <img src="assets/images/iconos/ig.svg" alt="Instagram">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<app-header></app-header>
<main>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer>