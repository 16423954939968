<section class="home-s1 testing">
    <div class="container-1280 homes1-container">
      <div class="migrate-modal">
          <div _ngcontent-ggw-c23="" class="content">
              <h3>El proceso de migración hacia Huobi ha finalizado con éxito</h3>

              <div class="huobi">
                  <div class="huobi-left pill">
                      <img src="https://www.bitex-staging.la/assets/images/logos/bitex.png" alt="Bitex" />
                  </div>
                  <div class="huobi-center pill">
                      <div class="pic-container">
                          <img src="https://www.bitex-staging.la/assets/images/logos/data-transfer.gif" alt="transfer" class="Transfer" />
                      </div>            
                  </div>
                  <div class="huobi-right pill">
                      <img src="https://www.bitex-staging.la/assets/images/logos/huobi-big.png" alt="Huobi" />
                  </div>
              </div>  
              <h4>Para recuperar el acceso a su cuenta<br><a href="https://www.huobi.com/en-us/?utm_source=bitex" class="w-btn">INGRESE AQUÍ</a></h4>   
              <p>En el <a class="underline" href="https://www.bitex-staging.la/assets/images/migracion-usuarios.pdf">siguiente archivo</a>, encontrará una serie de pasos que forman parte del proceso normal y formal para finalizar su proceso de autenticación en Huobi. Desde ya, en el caso de necesitar cualquier tipo de información o documentación para el proceso de validación de identidad no dude en contactarnos a<br><a href="mailto:hola@bitex.la" class="modal-contact">hola@bitex.la</a><br>Ademas, recuerde que ya puede contactarse con el soporte en linea de Huobi desde la web. <br><br><b><i>Bitex &amp; Huobi</i></b></p>
          </div>
      </div>      
        <div class="huobi-modal">
            <div class="content">
                <h3>¡AVISO IMPORTANTE!</h3>
                <p><b>A partir del 15 de Mayo de 2023 los usuarios de Bitex.la migrarán al exchange de Huobi que opera en la plataforma <a href="https://www.huobi.com/" target="_blank">www.huobi.com</a></b><br><b>HASTA EL 12/05/2023</b>, podrá transferir sus criptoactivos a la wallet o exchange que desee; también podrá tradearlo por dinero FIAT y retirarlo a la cuenta bancaria a su nombre.<br><b>DESDE el 12 de MAYO</b> a las 00:00hs, las transferencias - tanto de criptoactivos como de moneda fiduciaria- entrantes y salientes se verán interrumpidas. A raíz de esto, hemos enviado correos con novedades y acciones que serán necesarias de su parte para este proceso de cambio y transformación. Para mas información, no dude en contactarnos a <a href="mailto:hola@bitex.la">hola@bitex.la</a></p>
                <span class="close-huobi-modal">x</span>
            </div>
        </div>      
    	<div class="homes1-huobi">
        	<div class="homes1-huobi-left">
        	<img src="assets/images/logos/huobi.png" alt="Huobi">
            </div>
            <div class="homes1-huobi-center">
            	<h3>Together for</h3>
                <h4>Crypto Adoption <span>Worldwide!</span></h4>
            </div>
            <div class="homes1-huobi-right">
            <img src="assets/images/logos/bitex.png" alt="Bitex">
            </div>
        </div>
        <div class="homes1-title-container">
            <p class="homes1-title">Exchange</p>
            <p class="homes1-subtitle">Trading Internacional de criptoactivos</p>
        </div>
        <p class="homes1-text">Evolucione sus inversiones</p>
        <div class="homes1-icon-container">
            <div class="homes1-icon">
                <img src="assets/images/iconos/home-01.svg" alt="Atención personalizada">
                <p>Atención personalizada</p>
            </div>
            <div class="homes1-icon">
                <img src="assets/images/iconos/home-02.svg" alt="Custodia segura">
                <p>Custodia segura</p>
            </div>
            <div class="homes1-icon">
                <img src="assets/images/iconos/home-03.svg" alt="Acceso bancario local">
                <p>Acceso bancario local</p>
            </div>
        </div>
    </div>
</section>

<app-available-cryptos></app-available-cryptos>


<section class="home-s4">
    <div class="container-1280">
        <div class="homes3-container">
            <div class="homes3-img-container">
                <img src="assets/images/img/mariposa.svg" class="homes3-img desktop">
                <img src="assets/images/img/mariposa-mobile.svg" class="homes3-img mobile">
            </div>
            <div class="homes3-info-container">
                <p class="homes3-title">Acceda en moneda local</p>
                <div class="homes3-flag-container">
                    <div class="homes3-flag">
                        <img src="assets/images/flags/arg.svg" alt="Argentina">
                        <p>ARS</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/cl.svg" alt="Chile">
                        <p>CLP</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/peru.svg" alt="Perú">
                        <p>PEN</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/par.svg" alt="Paraguay">
                        <p>PYG</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/usa.svg" alt="EEUU">
                        <p>USD</p>
                    </div>
                    <div class="homes3-flag">
                        <img src="assets/images/flags/uyu.svg" alt="Uruguay">
                        <p>UYU</p>
                    </div>
                </div>
            </div>
        </div>
        <p class="homes4-title">Servicios especializados</p>
        <div class="homes4-card-container">
            <div class="homes4-card homes4-card1">
                <p class="homes4-card-title">Corporate</p>
                <p class="homes4-card-text">Un servicio dedicado y exclusivo para aquellas compañías que quieran invertir, diversificar, resguardar y potenciar el valor de sus activos en criptomonedas.
                </p>
                <a href="/home/corporate" class="homes4-card-btn">VER MÁS</a>
            </div>
            <div class="homes4-card homes4-card2">
                <p class="homes4-card-title">Advance</p>
                <p class="homes4-card-text">Una solución a medida para individuos de alto patrimonio que quieran diversificar sus inversiones en criptoactivos.</p>
                <a href="/home/advance" class="homes4-card-btn">VER MÁS</a>
            </div>
            <div class="homes4-card homes4-card3">
                <p class="homes4-card-title">Partners</p>
                <p class="homes4-card-text">Orientado a asesores independientes, banca privada y family office que buscan alternativas de inversión en activos digitales.</p>
                <a href="/home/partners" class="homes4-card-btn">VER MÁS</a>
            </div>
        </div>
        <div class="owl-carousel owl-theme carousel-homes4">
            <div class="item">
                <div class="homes4-card homes4-card1">
                    <p class="homes4-card-title">Corporate</p>
                    <p class="homes4-card-text">Un servicio dedicado y exclusivo para aquellas compañías que quieran invertir, diversificar, resguardar y potenciar el valor de sus activos en criptomonedas.
                    </p>
                    <a href="/home/corporate" class="homes4-card-btn">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes4-card homes4-card2">
                    <p class="homes4-card-title">Advance</p>
                    <p class="homes4-card-text">Una solución a medida para individuos de alto patrimonio que quieran diversificar sus inversiones en criptoactivos.</p>
                    <a href="/home/advance" class="homes4-card-btn">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes4-card homes4-card3">
                    <p class="homes4-card-title">Partners</p>
                    <p class="homes4-card-text">Orientado a asesores independientes, banca privada y family office que buscan alternativas de inversión en activos digitales.</p>
                    <a href="/home/advance" class="homes4-card-btn">VER MÁS</a>
                </div>
            </div>
        </div>

    </div>
</section>

<app-asset-safeguard></app-asset-safeguard>
