import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {

		setTimeout(() => {
			$('.go-to-contact').on('click', function (e) {
				e.preventDefault();
				var offset = $('#crmWebToEntityForm').offset()?.top;
				if (!offset) offset = 0;

				$("html, body").animate({
					scrollTop: offset - 100
				}, 500);
			});

		}, 200);



	}

}
