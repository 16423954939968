<section class="home-s2">
    <div class="container-1280">
        <p class="homes2-title">Criptoactivos disponibles</p>
        <div class="owl-carousel owl-theme carousel-homes2">
            <div class="item" data-hash="zero">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-bitcoin.svg" alt="Bitcoin">
                    </div>
                    <p>Bitcoin es una moneda peer to peer, lo que significa que todas las transacciones ocurren directamente entre participantes de la red iguales e independientes, sin la necesidad de que ningún intermediario las permita o facilite.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-usdt.svg" alt="USDT">
                    </div>
                    <p>USDT es una moneda estable (criptomoneda de valor estable) que refleja el precio del dólar estadounidense, emitida por una empresa con sede en Hong Kong, Tether. La vinculación del token con el USD se logra manteniendo una suma de
                        dólares en reservas que es igual a la cantidad de USDT en circulación.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-dai.svg" alt="DAI">
                    </div>
                    <p>DAI es una moneda estable basada en Ethereum (criptomoneda de precio estable) cuya emisión y desarrollo son gestionados por el Protocolo Maker y la organización autónoma descentralizada MakerDAO. El precio de DAI está vinculado al
                        dólar estadounidense y está garantizado por una combinación de otras criptomonedas que se depositan en bóvedas de contratos inteligentes cada vez que se acuña un nuevo DAI.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-binance.svg" alt="Binance">
                    </div>
                    <p>Binance ha lanzado todo un ecosistema de funcionalidades para sus usuarios. La red Binance incluye los proyectos Binance Chain, Academy, Trusted Wallet e Research, que emplean los poderes de la tecnología blockchain para llevar las
                        finanzas de la nueva era al mundo. Binance Coin es una parte integral del funcionamiento exitoso de muchos de los subproyectos de Binance.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-bitcoincash.svg" alt="Bitcoin Cash">
                    </div>
                    <p>Bitcoin Cash es un sistema de efectivo electrónico peer to peer que tiene como objetivo convertirse en dinero global sólido con pagos rápidos, micro tarifas, privacidad y alta capacidad de transacción (grandes bloques).</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-ethereum.svg" alt="Ethereum">
                    </div>
                    <p>Ethereum es un sistema de cadena de bloques de código abierto descentralizado que presenta su propia criptomoneda, Ether. ETH funciona como plataforma para muchas otras criptomonedas, así como para la ejecución de contratos inteligentes
                        descentralizados. Ethereum se describió por primera vez en un documento técnico de 2013 de Vitalik Buterin
                    </p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-polkadot.svg" alt="Polkadot">
                    </div>
                    <p>Polkadot es un protocolo multichain de fragmentación de código abierto que facilita la transferencia de cualquier tipo de datos o activos, no solo tokens, lo que hace que una amplia gama de cadenas de bloques sean interoperables entre
                        sí.
                    </p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <!--div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-ftt.svg" alt="FTT">
                    </div>
                    <p>FTT es el token de criptomoneda nativo de la plataforma de comercio de derivados criptográficos FTX que se lanzó el 8 de mayo de 2019. El equipo detrás de FTX comprende algunos de los comerciantes de criptomonedas más grandes de los
                        últimos años que, habiendo encontrado problemas con la mayoría de los intercambios de futuros de criptografía, decidieron lanzar su propia plataforma.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div-->
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-usdc.svg" alt="USDC">
                    </div>
                    <p>USD Coin es una stablecoin que está vinculada al dólar estadounidense en una base de 1:1. Todas las unidades de esta criptomoneda en circulación están respaldadas por $1 que se mantienen en reserva, en una combinación de efectivo y
                        bonos del Tesoro de Estados Unidos a corto plazo. El consorcio The Centre, que está detrás de este activo, dice que la USDC es emitida por instituciones financieras reguladas.
                    </p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-uniswap.svg" alt="Uniswap">
                    </div>
                    <p>Uniswap es un protocolo de comercio descentralizado popular, conocido por su papel en la facilitación del comercio automatizado de tokens de finanzas descentralizadas (DeFi). Tiene como objetivo mantener el comercio de tokens automatizado
                        y completamente abierto a cualquier persona que tenga tokens, al tiempo que mejora la eficiencia del comercio en comparación con los intercambios tradicionales.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-doge.svg" alt="Dogecoin">
                    </div>
                    <p>Dogecoin (DOGE) se basa en el popular meme de Internet "doge" y presenta un Shiba Inu en su logotipo. La moneda digital de código abierto fue creada por Billy Markus de Portland, Oregon y Jackson Palmer de Sydney, Australia, y se bifurcó
                        de Litecoin en diciembre de 2013.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-link.svg" alt="Chainlink">
                    </div>
                    <p>Fundada en 2017, Chainlink es una capa de abstracción de blockchain que permite contratos inteligentes conectados universalmente. A través de una red Oracle descentralizada, Chainlink permite que las cadenas de bloques interactúen
                        de forma segura con fuentes de datos externos, eventos y métodos de pago, proporcionando la información crítica fuera de la cadena que necesitan los contratos inteligentes complejos para convertirse en la forma dominante de acuerdo
                        digital.
                    </p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-soya.svg" alt="SOYA">
                    </div>
                    <p>Es la primera stablecoin colaterizada en soja. Esto quiere decir que es un criptoactivo digital respaldado por granos de soja. Por cada Token de Criptosoja (SOYA) hay una tonelada de soja real que lo respalda. SOYA es un Token ERC
                        20 que funciona sobre la Blockchain de Ethereum, y tiene como valor de referencia al precio de la soja del país en que se produce.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-yfi.svg" alt="YFI">
                    </div>
                    <p>Yearn.finance es un servicio para inversores de finanzas descentralizadas (DeFi), que utiliza la automatización para permitirles maximizar las ganancias de la agricultura de rendimiento. Su objetivo es simplificar el espacio de DeFi
                        en constante expansión para los inversores que no tienen una mentalidad técnica o que desean interactuar de una manera menos comprometida que los operadores serios.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
            <div class="item">
                <div class="homes2-item-container">
                    <div class="homes2-item-img">
                        <img src="assets/images/logos/logo-sushi.svg" alt="SushiSwap">
                    </div>
                    <p>SushiSwap (SUSHI) es un ejemplo de un creador de mercado automatizado (AMM). Una herramienta cada vez más popular entre los usuarios de criptomonedas, los AMM son intercambios descentralizados que utilizan contratos inteligentes para
                        crear mercados para cualquier par de tokens.</p>
                    <a href="https://production-bitex-marketing-static-assets.s3.amazonaws.com/bitex-criptoactivos.pdf" target="_blank" class="homes2-link">VER MÁS</a>
                </div>
            </div>
        </div>
    </div>
</section>
