import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-local-currency',
	templateUrl: './local-currency.component.html',
	styleUrls: ['./local-currency.component.css']
})
export class LocalCurrencyComponent implements OnInit {

	url: any = "";
	constructor(
		private router: Router
	) {

		this.url = this.router.url;
	}

	ngOnInit(): void {
	}

}
